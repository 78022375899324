import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config/config';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import { i18n } from '../../i18n';
import COOKIES from '../constants/COOKIES';
import { getToken } from '../lib/auth';
import { getCookie, setCookie } from '../lib/cookie';

const api = axios.create({
  baseURL: config.API_URL,
  responseType: 'json',
});

export const ticketsApi = axios.create({
  baseURL: config.TICKETS_API_URL,
  responseType: 'json',
});

const interceptorsRequestHandler = (axiosConfig: AxiosRequestConfig) => {
  const token = getToken();

  if (axiosConfig.headers.Authorization && !token) {
    return axiosConfig;
  }

  const lngConfig = get(axiosConfig, 'lng');
  let { url, params } = axiosConfig;

  if (url && url.includes('landing-api')) {
    const companyId = getCookie(COOKIES.COMPANY);
    params = { ...params, company_id: companyId };
  }

  if (lngConfig) {
    url =
      url?.indexOf('?') !== -1
        ? url?.replace('?', `/translation/${lngConfig}?`)
        : `${url}/translation/${lngConfig}`;
  }

  const isClientSide = typeof window !== 'undefined';
  const isNewEventPage =
    isClientSide &&
    window.location.pathname &&
    window.location.pathname.includes('events_v2') &&
    url?.startsWith('event');

  if (isClientSide && isNewEventPage) {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.baseURL = 'https://event-api.pbilet.org/api/';
  }
  const isCommonApi = url && url.includes(config.COMMON_API_URL);

  return {
    ...axiosConfig,
    url,
    params,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...axiosConfig.headers,
      ...(token && !isCommonApi && { 'internal-auth-token': `Token ${config.INTERNAL_AUTH_TOKEN}` }),
      'Accept-Language':
        axiosConfig.headers['Accept-Language'] ||
        get(axiosConfig, 'lng') ||
        get(axiosConfig, 'reduxSourceAction.payload.lng', i18n?.language || 'ru'),
    },
  };
};

const interceptorsResponseHandler = (response: AxiosResponse<unknown>) => {
  if (response.headers['x-refresh-access-token']) {
    const newToken = response.headers['x-refresh-access-token'];
    setCookie(COOKIES.TOKEN, newToken);
  }

  if (response.config.reduxSourceAction && response.config.reduxSourceAction.payload.withNotification) {
    NotificationManager.success('Успешно');
  }

  return response;
};

api.interceptors.request.use(interceptorsRequestHandler, (error) => Promise.reject(error));

api.interceptors.response.use(interceptorsResponseHandler, (error) => {
  if (error.message === 'Network Error') {
    NotificationManager.error('Ошибка сети');
  }

  return Promise.reject(error);
});

ticketsApi.interceptors.request.use(interceptorsRequestHandler, (error) => Promise.reject(error));

ticketsApi.interceptors.response.use(interceptorsResponseHandler, (error) => {
  if (error.message === 'Network Error') {
    NotificationManager.error('Ошибка сети');
  }

  return Promise.reject(error);
});

export default api;
