export default {
  XXS: '360px',
  XS: '400px',
  SM: '576px',
  MD: '768px',
  MD_NUMBER: 768,
  LG: '992px',
  XL: '1200px',
  XL_NUMBER: 1200,
  XL_LANDING_EVENTS: '1201px',

  LAPTOP: '1440px',
  FULL_HD: '1920px',
};
