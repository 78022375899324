export default {
  GRAYE1: '#E1E1E1',
  GRAYF5: '#F5F5F5',
  GRAY5C: '#5c5c5c',
  GRAY_MERCURY: '#E3E1E0',
  BLUE: '#2F80ED',
  GREEN: '#2DC472',
  SUCCESS: '#27AE60',
  YELLOW: '#FFDA00',
  RED: '#EB5757',
  PINK: '#fef2f2',
};
