import moment from 'moment';
import first from 'lodash/first';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';

export const isPasteDate = (date: string) => moment(date, DATE_FORMATS.DATE_TIME).isBefore(moment());

export const isNextYear = (date: string) => moment(date, DATE_FORMATS.DATE).isAfter(moment(), 'year');

export const dateWithoutOffset = (date: Date | string) => moment.utc(date).format();

export const combineDateAndTime = (dateMoment, timeMoment) => {
  const newMoment = dateMoment.clone();

  newMoment.set({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
    millisecond: timeMoment.millisecond(),
  });
  return newMoment;
};

export const generateDatesArray = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  times: Array<{ time_start: moment.Moment; time_end: moment.Moment }>,
) => {
  const datesArray: Array<{ startDate: moment.Moment; endDate: moment.Moment }> = [];
  const filteredTimes = times.filter(
    (o) => typeof o.time_start !== 'undefined' && typeof o.time_end !== 'undefined',
  );

  const firstDate = first<moment.Moment>(
    filteredTimes.map(({ time_start }) => combineDateAndTime(dateStart.clone(), time_start)),
  );
  const lastDate = first<moment.Moment>(
    filteredTimes.map(({ time_end }) => combineDateAndTime(dateEnd.clone(), time_end)),
  );
  const clonedStartDate = firstDate.clone();

  while (clonedStartDate.isSameOrBefore(lastDate)) {
    filteredTimes.forEach(({ time_start, time_end }) => {
      const startDate = combineDateAndTime(clonedStartDate.clone(), time_start);
      const endDate = combineDateAndTime(clonedStartDate.clone(), time_end);
      datesArray.push({ startDate, endDate });
    });

    clonedStartDate.add(1, 'day');
  }

  return datesArray;
};
