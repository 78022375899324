import { LANGUAGES } from 'shared/constants/LANGUAGES';

const config = {
  API_URL: process.env.API_URL,
  LANDING_DOMAIN: process.env.LANDING_DOMAIN,
  LANDING_API_URL: process.env.LANDING_API_URL,
  EVENT_API_URL: process.env.EVENT_API_URL,
  TICKETS_API_URL: process.env.TICKETS_API_URL,
  PRICES_API_URL: process.env.PRICES_API_URL,
  TICKETS_SOURCE_URL: process.env.TICKETS_SOURCE_URL,
  CRAWLER_API_URL: process.env.CRAWLER_API_URL,
  WS_API_URL: process.env.WS_API_URL,
  WS_HOST_API_URL: process.env.WS_HOST_API_URL,
  COMMON_API_URL: process.env.COMMON_API_URL || '',
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  EDITOR_DOMAIN: process.env.EDITOR_DOMAIN,
  PORTALBILET_COMPANY_ID: +(process.env.PORTALBILET_COMPANY_ID || '0'),
  production: process.env.NODE_ENV === 'production',
  INTERNAL_AUTH_TOKEN: process.env.INTERNAL_AUTH_TOKEN,
  LANDING_QUEUE_API_URL: process.env.LANDING_QUEUE_API_URL,
  PLACES_API_URL: process.env.PLACES_API_URL,
  i18n: {
    browserLanguageDetection: false,
    serverLanguageDetection: false,
    localePath: 'static/locales',
    defaultLanguage: LANGUAGES.RU,
    fallbackLng: LANGUAGES.RU,
    lng: LANGUAGES.RU,
    otherLanguages: [LANGUAGES.EN],
  },
};

export default config;
