export const LANGUAGES: {
  [key: string]: string;
} = {
  RU: 'ru',
  EN: 'en',
  FR: 'fr',
  DE: 'de',
  ES: 'es',
  UK: 'uk',
  KK: 'kk',
  ZH: 'zh',
  JA: 'ja',
  AR: 'ar',
  KY: 'ky',
  HY: 'hy',
  UZ: 'uz',
  AZ: 'az',
  BE: 'be',
  HE: 'he',
  KA: 'ka',
  TR: 'tr',
};

export const LANGUAGES_LIST = [LANGUAGES.RU, LANGUAGES.EN];

export const LANGUAGES_NAMES = {
  ru: 'Русский',
  en: 'Английский',
  fr: 'Французский',
  de: 'Немецкий',
  es: 'Испанский',
  uk: 'Украинский',
  kk: 'Казахский',
  zh: 'Китайский',
  ja: 'Японский',
  ar: 'Арабский',
  ky: 'Киргизский',
  hy: 'Армянский',
  uz: 'Узбекский',
  az: 'Азербайджанский',
  be: 'Белорусский',
  he: 'Иврит',
  ka: 'Грузинский',
  tr: 'Турецкий',
};
